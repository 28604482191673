<template>
  <b-row align-h="center">
    <b-col>
      <b-overlay>
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <div class="h4">On-chain voting</div>
            <template v-if="stepperUuid && !isStepExists(stepperUuid)">
              <div v-if="!isTokens" class="mt-3 text-left">
                <div class="caption info">
                  LQF tokens required
                  <span v-b-tooltip.hover title="tooltip" clickable>
                  <b-icon-exclamation-circle></b-icon-exclamation-circle>
                </span>
                </div>
                <div>
                  LQF governance tokens required to create proposal or vote on existing one.
                  You can get tokens by depositing pool tokens or by swapping tokens.
                </div>
              </div>
              <b-tabs class="mt-3 mx-n5" align="center"
                      v-model="selectedVotingTab">
                <b-tab title="Active" :active="selectedVotingTab === 0"></b-tab>
                <b-tab title="Submit proposal" :active="selectedVotingTab === 1"></b-tab>
                <b-tab title="History" :active="selectedVotingTab === 2"></b-tab>
              </b-tabs>
            </template>
          </template>

          <template v-if="selectedVotingTab === 0">
            <div class="card-table borderless my-3">
              <b-table borderless
                       id="active-voting"
                       :fields="existingActiveFields"
                       :items="getActiveVoting"
                       tbody-tr-class="hover-link"
                       v-if="getActiveVoting.length > 0"
                       @row-clicked="rowClickHandler">
                <template v-slot:cell(proposal)="{item}">
                  {{ item.proposal_title }}
                </template>
                <template v-slot:cell(end)="{item}">
                  {{ getDate(item.end_date) }}
                </template>
                <template v-slot:cell(votes)="{item}">
                  <div class="text-nowrap">
                    {{ getPercentageRatio(item.votes_yes, item.votes_no).yes }}% yes
                    /
                    {{ getPercentageRatio(item.votes_yes, item.votes_no).no }}% no
                  </div>
                </template>
              </b-table>
              <div class="px-5">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalElements"
                    :per-page="perPage"
                    first-number
                    last-number
                    align="center"
                    aria-controls="active-voting"
                    @input="handleInput"
                ></b-pagination>
              </div>

            </div>
          </template>

          <template v-else-if="selectedVotingTab === 1">
            <div class="b-card-content">
              <template v-if="!isStepExists(stepperUuid)">
                <b-form>
                  <div class="form-pool-choice px-5 pt-3 pb-4">
                    <div class="caption">
                      Proposal
                      <span v-b-tooltip.hover title="Type of proposal you will create" clickable>
                      <b-icon-question-circle></b-icon-question-circle>
                    </span>
                    </div>
                    <b-input-group id="proposal">
                      <b-form-input
                          readonly
                          placeholder="Choose proposal"
                          :value="selectedProposal.proposal_title"
                          data-lpignore="true">
                      </b-form-input>
                      <b-input-group-append>
                        <div>
                          <b-button variant="white">
                            <b-icon-chevron-compact-down class="mx-auto"></b-icon-chevron-compact-down>
                          </b-button>
                        </div>
                      </b-input-group-append>
                    </b-input-group>
                    <b-popover target="proposal"
                               ref="proposal"
                               triggers="click blur"
                               placement="bottomcenter"
                               custom-class="select-options full">
                      <div class="options">
                        <b-row class="option" v-for="(item,index) in proposalList"
                               :key="index" v-on:click="chooseProposal(item)">
                          <b-col>
                            {{ item.proposal_title }}
                          </b-col>
                        </b-row>
                      </div>

                    </b-popover>

                  </div>

                  <SubmitProposalForm
                      ref="submitProposalForm"
                      v-if="selectedProposal"
                      :proposalType="1">
                  </SubmitProposalForm>

                </b-form>
                <div class="card-footer">
                  <b-button block variant="primary" class="button-flat mt-3"
                            v-on:click="proposalPopup">
                    Submit proposal
                  </b-button>
                </div>
              </template>
              <template v-else-if="isStepExists(stepperUuid)">
                <Step :con-data="confirmationPopupData" @close="onCloseStepper"></Step>
                <StepInformer :steps="getStepsById(stepperUuid)" :stepperUuid="stepperUuid"></StepInformer>
              </template>

            </div>
          </template>

          <template v-else>
            <div class="card-table borderless my-3">
              <b-table borderless
                       id="history-voting"
                       :fields="existingHistoryFields"
                       :items="getHistoryVoting"
                       tbody-tr-class="hover-link"
                       v-if="getHistoryVoting.length > 0"
                       @row-clicked="rowClickHandler">
                <template v-slot:cell(proposal)="{item}">
                  {{ item.proposal_title }}
                </template>
                <template v-slot:cell(votingEnded)="{item}">
                  {{ getDate(item.end_date) }}
                </template>
                <template v-slot:cell(results)="{item}">
                  {{ item.voting_result }}
                </template>
              </b-table>
              <div class="px-5">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalElements"
                    :per-page="perPage"
                    first-number
                    last-number
                    align="center"
                    aria-controls="history-voting"
                    @input="handleInput"
                ></b-pagination>
              </div>

            </div>

          </template>
        </b-card>
      </b-overlay>
    </b-col>
    <ConfirmationGovernanceModal :ref="'proposal-confirmation-modal'"
                                 :id="'proposal-confirmation-modal'"
                                 :con-data="confirmationPopupData"
                                 @ok="onConfirmOk"
    ></ConfirmationGovernanceModal>
  </b-row>
</template>

<script>
import moment from 'moment'
import {mapActions, mapGetters} from "vuex";
import {CREATE_PROPOSAL, HTTP_GET_GOVERNANCE_VOTING} from "@/store/modules/voting.module";
import {mathRound} from "@/helpers/utils";
import BigNumber from "bignumber.js";
import {DELETE_STEP_BY_ID, INIT_STEP} from "@/store/modules/stepper.module";
import {uuid} from "vue-uuid";

const TAB_ACTIVE = 0
// const TAB_SUBMIT = 1
const TAB_HISTORY = 2

export default {
  name: "Voting",
  components: {
    SubmitProposalForm: () => import("@/components/voting/SubmitProposalForm"),
    ConfirmationGovernanceModal: () => import("@/components/confirmation/governance/ConfirmationGovernanceModal"),
    Step: () => import("@/components/stepper/Step"),
    StepInformer: () => import("@/components/stepper/StepInformer"),
  },
  data: function () {
    return {
      isTokens: true,
      selectedVotingTab: 0,
      confirmationPopupData: {},
      existingActiveFields: [
        {key: 'proposal', thClass: 'caption'},
        {key: 'end', thClass: 'caption'},
        {key: 'votes', thClass: 'caption'},
      ],
      existingHistoryFields: [
        {key: 'proposal', thClass: 'caption'},
        {key: 'votingEnded', thClass: 'caption'},
        {key: 'results', thClass: 'caption'},
      ],
      getActiveVoting: [],
      getHistoryVoting: [],
      perPage: 5,
      currentPage: 1,
      totalElements: 0,

      proposalList: [
        {proposal_title: 'Change governance contract', proposal_type: 1},
      ],
      selectedProposal: '',
      stepperUuid: uuid.v1(),
    }
  },
  watch: {
    'selectedVotingTab': {
      handler: async function (val) {
        this.reset()
        switch (val) {
          case TAB_ACTIVE:
            await this.getVoting()
            break;
          case TAB_HISTORY:
            await this.getVoting(true)
            break
        }
      },
      immediate: true
    },
  },
  methods: {
    ...mapActions([
      HTTP_GET_GOVERNANCE_VOTING,
      CREATE_PROPOSAL,
      INIT_STEP,
      DELETE_STEP_BY_ID
    ]),
    reset(withTab = false) {
      let resetData = {
        getActiveVoting: [],
        getHistoryVoting: [],
        perPage: 5,
        currentPage: 1,
        totalElements: 0,
      }
      if (withTab) {
        resetData.selectedVotingTab = TAB_ACTIVE
      }
      Object.assign(this.$data, resetData)
    },
    async getVoting(historic = false) {
      let params = {
        page: this.currentPage - 1,
        size: this.perPage,
        historic: historic
      }
      await this.httpGetGovernanceVoting(params).then(res => {
        this.perPage = res.size
        this.page = res.number
        this.totalElements = res.totalElements
        params.historic ? this.getHistoryVoting = res.content : this.getActiveVoting = res.content
        // eslint-disable-next-line no-unused-vars
      }).catch(e => {
        this.reset()
      })
    },
    async handleInput($event) {
      this.currentPage = $event
      await this.getVoting(this.selectedVotingTab !== 0)
    },
    rows(list) {
      return list.length
    },
    chooseProposal(proposal) {
      this.selectedProposal = proposal
      this.$root.$emit('bv::hide::popover', 'proposal')
    },
    getPercentageRatio(yes, no) {
      const sum = new BigNumber(yes).plus(no)
      let yesResult = new BigNumber(yes).dividedBy(sum).integerValue()
      let noResult = new BigNumber(no).dividedBy(sum).integerValue()
      return {
        yes: mathRound((!isNaN(yesResult) ? yesResult : 0) * 100, 0),
        no: mathRound((!isNaN(noResult) ? noResult : 0) * 100, 0)
      }
    },
    getDate: function (date) {
      return moment.unix(date).format('DD/MM/YY HH:mm');
    },
    rowClickHandler(record) {
      this.$router.push({path: 'voting/proposal/' + record.proposal_id})
    },
    proposalPopup() {
      this.assignConfirmationData({waiting: false, modalType: 'confirm'})
      this.confirmationPopupData = this.getConfirmationData()
      if (this.confirmationPopupData) {
        this.$refs['proposal-confirmation-modal'].$refs['confirmation-governance-modal'].show()
      }
    },
    getConfirmationData() {
      let confirmationData = {
        title: '', subtitle: '', modalType: 'confirm', waiting: false, pool: {}, listData: [],
      }
      const data = this.$refs['submitProposalForm'].$data

      return Object.assign(confirmationData, data.conData)
    },
    assignConfirmationData(data) {
      this.confirmationPopupData = Object.assign(this.confirmationPopupData, data)
    },
    onConfirmOk(op) {
      if (op) {
        this.initStep(this.stepperUuid)
        this.$refs['proposal-confirmation-modal'].$refs['confirmation-governance-modal'].hide()
        this.performProposal();
      }
    },
    async onCloseStepper() {
      this.deleteStepById(this.stepperUuid)
      this.selectedVotingTab = TAB_ACTIVE
      this.stepperUuid = uuid.v1()
    },
    async performProposal() {
      this.assignConfirmationData({waiting: true, modalType: 'confirm'})
      let data = this.$refs['submitProposalForm'].$data.form
      data.stepUuid = this.stepperUuid
      await this.createProposal(data).then(async (tx) => {
        this.assignConfirmationData({
          etherscanURL: this.getEtherscanTxLink(tx.transactionHash),
          successMessage: 'Success!',
          waiting: false,
          modalType: 'success'
        })
        this.selectedVotingTab = TAB_ACTIVE
      }).catch((e) => {
        this.assignConfirmationData(
            {modalType: 'error', errorMessage: e.message, waiting: false})
      })
    },
  },
  computed: {
    ...mapGetters([
      'getEtherscanTxLink',
      'isStepExists',
      'getStepsById'
    ])
  }
}
</script>

<style scoped lang="scss">

  .card-header {
    border-bottom: none
  }

  .form-pool-choice {
    border-top: 1px solid $gray-300;
  }

  .table td:last-of-type {
    white-space: nowrap
  }

</style>
